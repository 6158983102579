body.no-scroll {
  overflow-y: hidden;
}

pre {
  font-family: "Commons", sans-serif;
}

.btn {
  padding: 17px 32px;
  border-radius: 2px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  transition: all 0.2s linear;
  font-family: "Travels", sans-serif;
  text-decoration: none;
  text-align: center;
  box-sizing: border-box;
  &:hover {
    text-decoration: none;
  }
  &.success {
    pointer-events: none;
    background-color: #24b24c !important;
  }
  &.fail {
    pointer-events: none;
    background-color: #ffd9db !important;
    color: #f21d28 !important;
  }
  &.deactive {
    pointer-events: none;
    color: $b_indigo !important;
    background-color: $n_light !important;

    &.load {
      &::after {
        content: ".";
        animation: loading 1s ease alternate infinite;
      }
    }

    &.alt {
      background-color: #c9c9de !important;
      color: #fff !important;
    }

    &:hover {
      box-shadow: none;
      transform: none;
      color: $b_indigo !important;
      opacity: 1;
    }
  }

  &_full {
    width: 100%;
  }
  &__switch {
    display: flex;
    .btn {
      border-radius: 0px;
      &:first-child {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }

      &:last-child {
        border-top-right-radius: 2px;
        border-bottom-left-radius: 2px;
      }
    }
  }
}
@keyframes loading {
  60% {
    text-shadow: 0.35em 0 0 currentColor;
  }
  100% {
    text-shadow: 0.35em 0 0 currentColor, 0.75em 0 0 currentColor;
  }
}
.btn_sm {
  padding: 10px 17px;
}

.btn--menu {
  font-size: 14px !important;
  height: 40px;
  padding: 0;
  min-width: 130px;
  text-align: center;
  position: relative;

  padding-top: 0 !important;
  padding-bottom: 0 !important;
  span,
  .i-close {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.btn_primary {
  color: white;
  background-color: $b_indigo;
  &:hover {
    color: white;
    background-color: $b_violet;
  }
}

.btn_secondary {
  color: $b_indigo;
  background-color: $n_light;
  &:hover {
    color: $b_violet;
  }
}

.card-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Travels", sans-serif;
  font-size: 14px;
  line-height: 16.8px;
  color: $n_darkest;
  & > a {
    padding-left: 10px;
    padding-right: 10px;
    transition: all 0.2s;
    &.active {
      color: $b_indigo;
    }
    &:hover {
      color: $b_indigo;
    }
  }
}

.slick-next:before,
.slick-prev:before {
  //test
  font-family: "Travels", sans-serif;
  color: black;
  font-size: 24px;
}

.slick-next,
.slick-prev {
  width: 24px;
  height: 24px;
}

.slick-prev {
  &::before {
    display: inline-block;
    transform: rotate(180deg) translateY(0.5px);
  }
}

.slick-next.slick-disabled:before {
  opacity: 1 !important;
  color: $b_light !important;
}
.slick-prev:before,
.slick-next:before {
  color: $b_indigo !important;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #070713;
  opacity: 0.5;
  transition: all 0.3s linear;
  z-index: 100;
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slick-next,
.slick-prev {
  &::before {
    font-family: "icons" !important;
    content: "\e900" !important;
    transition: all 0.2s;
    color: $b_indigo;
  }
}

.slick-df {
  display: flex;
  width: 100%;
  .slick-list {
    width: 100%;
  }
  .slick-track {
    margin-left: 0;
  }
  .slick-next {
    order: 2;
  }

  .slick-prev {
    order: 1;
  }
  .slick-next,
  .slick-prev {
    position: relative;
    left: auto;
    right: auto;
    transform: initial;
  }
}

@for $i from 1 through 10 {
  .mt#{$i*5} {
    margin-top: 5px * $i;
    &-s {
      @include max($s) {
        margin-top: 5px * $i;
      }
    }
  }
  .mb#{$i*5} {
    margin-bottom: 5px * $i;
    &-s {
      @include max($s) {
        margin-bottom: 5px * $i;
      }
    }
  }
  .ml#{$i*5} {
    margin-left: 5px * $i;
    &-m {
      @include max($s) {
        margin-left: 5px * $i;
      }
    }
  }
  .mr#{$i*5} {
    margin-right: 5px * $i;
    &-m {
      @include max($s) {
        margin-right: 5px * $i;
      }
    }
  }
}

.simplebar-scrollbar:before {
  width: 100%;
  border-radius: 2px;
  right: 0;
  left: 0;
  background-color: $b_indigo;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 0;
  bottom: 0;
}

.simplebar-track.simplebar-vertical {
  width: 4px;
  background-color: $b_light;
  border-radius: 2px;
}

.rc-slider {
  &-handle {
    border: none !important;
    background-color: $n_middle !important;
    &-dragging {
      background-color: $b_indigo !important;
      box-shadow: none !important;
    }
  }
  &-rail {
    background-color: $b_light !important;
  }
  &-track {
    background-color: $b_sky !important;
  }
  &-dot {
    border-color: $b_light !important;
    &-active {
      background-color: $b_indigo !important;
      border: none !important;
    }
  }
  &-tooltip {
    &-inner {
      position: relative;
      top: 6px !important;
      font-size: 15px !important;
      font-family: "Commons" !important;
      background-color: transparent !important;
      padding: 0 !important;
      height: initial !important;
      box-shadow: initial !important;
      color: $n_dark !important;
    }
  }
}

.slick-track {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.CDEK-widget__popup {
  width: 70vw !important;
  height: 60vh !important;
}

.d-flex {
  display: flex;

  &.center {
    align-items: center;
  }
  &.bottom {
    align-items: flex-end;
  }
  &.top {
    align-items: flex-start;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.type-row {
    box-sizing: border-box;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.f-space-b {
  justify-content: space-between;
}

.status-bar {
  width: 100%;
  height: 15px;
  border-radius: 2px;
  background-color: #fff;

  &__complited {
    height: 100%;
    background-color: #096ade;
    border-radius: 2px;
  }
}

.status-pic {
  width: 84px;
  height: 84px;
  @include max($s) {
    width: 40px;
    height: 40px;
  }
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  &__start {
    background-image: url("../img/status/status1.png");
  }
  &__bronze {
    background-image: url("../img/status/status2.png");
  }
  &__silver {
    background-image: url("../img/status/status3.png");
  }
  &__gold {
    background-image: url("../img/status/status4.png");
  }
  &__max {
    background-image: url("../img/status/status5.png");
  }

  &__in-bonus {
    width: 60px;
    height: 60px;

    // @include max($s) {
    //   width: 40px;
    //   height: 40px;
    // }
  }
}

.v-middle {
  display: flex;
  align-items: center;
}

i:hover {
  text-decoration: none;
}

.no-scroll {
  overflow: hidden !important;
}

.react-select--drop__menu {
  width: calc(100% - 20px) !important;
}

.divider {
  width: 100%;
  border-bottom: 1px solid #e8f1fd;

  position: relative;
  top: 50px;
  @include max($s) {
    display: none;
  }
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .content {
    flex: 1 0 auto;
    position: relative;
  }
}

.cart-btn {
  position: relative;

  &__counter {
    font-family: "Commons", sans-serif;
    vertical-align: middle;
    color: #fff;
    font-size: 13px;
    line-height: 13px;
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: $b_indigo;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    @include max($s) {
      width: 17px;
      height: 17px;
      align-items: center;
    }
  }
}

.p-0 {
  padding: 0px !important;
}

.pin {
  display: flex;
  background: $b_light;
  border-radius: 3px;
  font-size: 15px;
  line-height: 15px;
  color: $b_indigo;
  padding: 4px 6px 2px;
  font-weight: normal;
  font-family: "Commons";

  &_inline {
    display: inline-flex;
  }

  // &_cart {
  //   margin-top: 10px;
  // }

  @include max($s) {
    font-size: 12px;
    line-height: 12px;

    &_cart {
      margin-bottom: 15px;
    }
  }
}

.pointer {
  cursor: pointer !important;
}

.error-boundary {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  color: #000;
  font-family: "Commons", sans-serif;
  font-size: 24px;
  line-height: 24px;
  gap: 20px;

  p {
    text-align: center;
  }
}
