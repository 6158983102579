.prod-card {
  position: relative;
  width: 100%;
  text-align: left;
  color: $n_darkest;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    .prod-card__size {
      opacity: 1;
    }
    .prod-card__name {
      text-decoration: underline;
      color: $b_indigo;
    }
  }
  @include max($s) {
    width: 160px;
  }
  &__img {
    position: relative;
    width: 100%;
    padding: 50% 0px;
    // overflow: hidden;

    &-wrp {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      span {
        width: 100%;
        height: 100%;
        display: flex !important;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  &__bages {
    position: absolute;
    left: 0;
    bottom: 0px;
    font-size: 15px;
    line-height: 15px;
    display: flex;

    & > div {
      margin-right: 5px;
      &:last-of-type {
        margin-right: 0px;
      }
    }
  }
  &__bage {
    padding: 6px 6px 2px 6px;
    border-radius: 3px;
    font-weight: 600;
  }
  &__news {
    background-color: $b_light;
    padding: 6px 6px 2px 6px;
    border-radius: 3px;
    display: inline-block;
    color: $r_dark;
    font-weight: 600;
    &.both {
      // padding-left: 10px;
      // margin-left: 5px;
    }
    @include max($s) {
      font-size: 12px;
      line-height: 12px;
      top: 125px;
    }
  }
  &__sale {
    background-color: $r_red;
    padding: 6px 6px 2px 6px;
    border-radius: 3px;
    display: inline-block;
    color: $white;
    font-weight: 600;
    z-index: 2;
    position: relative;
    @include max($s) {
      font-size: 12px;
      line-height: 12px;
    }
  }
  &__comparison {
    display: flex;
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 18px;
    line-height: 20.7px;
    @include max($s) {
      font-size: 16px;
      line-height: 18.4px;
    }
    &-old {
      color: $n_grey;
      text-decoration: line-through;
      padding-right: 10px;
    }
    &-new {
      color: $r_red;
    }
  }
  &__price {
    font-size: 18px;
    line-height: 20.7px;
    margin-top: 10px;
    margin-bottom: 5px;
    color: $n_darkest;
    @include max($s) {
      font-size: 16px;
      line-height: 18px;
    }
  }
  &__name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    height: 40px;
    width: 240px;
    transition: all 0.2s;
    @include max($s) {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      height: 36px;
      width: 100%;
    }
  }
  &__size {
    opacity: 0; //размеры видны только при наведении
    transition: all 0.3s linear;

    background-color: $b_light;
    color: $n_darkest;
    padding: 4px;
    border-radius: 3px;
    display: inline-block;
    font-weight: 400 !important;

    position: absolute;

    top: 0px;
    left: 0px;
  }
}

.prod-col {
  box-sizing: border-box;
  border-bottom: #e8f1fd 1px solid;
  border-right: #e8f1fd 1px solid;
  padding-top: 10px;

  .loading {
    color: transparent;
    background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
    background-size: 400%;
    animation: loading 1.2s ease-in-out infinite;

    &:first-child {
      height: 295px;
      @include max($s) {
        height: 168px;
      }
    }

    &:last-child {
      height: 46px;
      @include max($s) {
        height: 48px;
      }
    }
  }

  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  @include max($s) {
    padding-bottom: 10px;
    padding-top: 10px;

    &.three-line {
      border-right: none;
      border-bottom: none;
    }

    &.four-line {
      &:nth-child(2n) {
        border-right: none;
      }
    }
    // &:nth-of-type(-n + 2) {
    //   border-top: none;
    // }
  }
  @include min($s) {
    @include max($m) {
      &.three-line {
        &:nth-child(2n) {
          border-right: none;
        }
      }

      &.four-line {
        &:nth-child(3n) {
          border-right: none;
        }
      }
      // &:nth-of-type(-n + 2) {
      //   border-top: none;
      // }
    }
  }

  @include min($m) {
    &.three-line {
      &:nth-child(3n) {
        border-right: none;
      }
      &:nth-child(3n + 1) {
        .prod-card {
          &__price {
            margin-left: -10px;
          }
          &__comparison {
            margin-left: -10px;
          }
          &__name {
            margin-left: -10px;
            @include max($s) {
            }
          }
          &__size {
            left: -10px;
          }
          &__bages {
            left: -10px;
          }
        }
      }
      // &:nth-of-type(-n + 3) {
      //   border-top: none;
      // }
    }
    &.four-line {
      &:nth-child(4n) {
        border-right: none;
      }

      &:nth-child(4n + 1) {
        .prod-card {
          &__price {
            margin-left: -10px;
          }
          &__comparison {
            margin-left: -10px;
          }
          &__name {
            margin-left: -10px;
          }
          &__size {
            left: -10px;
          }
          &__bages {
            left: -10px;
          }
        }
      }

      &:nth-last-child(-n + 4) {
        border-bottom: none;
      }
    }
  }
}
