.catalog {
  padding-bottom: 100px;
  &__head {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    border-bottom: 1px solid $b_light;

    @include max($s) {
      // border: none;
      margin-top: 30px;
      padding-bottom: 22px;
      margin-bottom: 10px;
    }

    &-title {
      font-family: "Travels", sans-serif;

      margin-top: auto;
      vertical-align: bottom;
      font-size: 24px;
      line-height: 33.6px;
      font-weight: 600;
      color: $n_darkest;

      margin-bottom: 40px;

      @include max($s) {
        @include h3-m;
        margin-bottom: 0px;
      }
    }
    &-select {
      margin-left: auto;
      color: $n_darkest;
      width: 260px;
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-bottom: 20px;

      @include max($s) {
        padding-bottom: 0px;
      }

      &-item {
        margin-right: 24px;
        margin-bottom: 12px;
        @include max($s) {
          margin-right: 30px;
          margin-bottom: 8px;
          & > a {
            padding: 6px 6px 6px 0px;
            text-decoration: underline;
          }
        }
        & > a {
          display: inline-block;
        }
      }

      &-link {
        color: $b_indigo;
        padding: 4px 4px 4px 0px;
      }
    }
    &-description {
      font-size: 15px;
      line-height: 15px;
      color: $n_grey;
      margin-bottom: 20px;

      @include max($s) {
        margin-top: 10px;
        margin-bottom: 0px;
      }
    }
    &-filter {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      flex-grow: 0;

      @include max($s) {
        margin-bottom: 30px;
      }

      &__active {
        display: flex;
        align-items: center;

        padding: 8px 10px 8px 12px;

        background-color: #fafafd;
        border-radius: 2px;

        margin-right: 14px;
        margin-bottom: 14px;

        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        color: $n_grey;

        cursor: default;

        @include max($s) {
          margin-left: 0px;
          margin-right: 14px;
        }

        &:last-of-type {
          color: $b_sky;
          cursor: pointer;
        }

        &:hover {
          color: $b_indigo;
        }

        i {
          font-size: 16px;
          margin-left: 6px;
          cursor: pointer;

          &::before {
            vertical-align: top;
            position: relative;
            top: -1px;
          }
        }
      }
    }
  }
  &__sort {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  &__cards {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  &__pagination {
    margin-top: 50px;

    & > a,
    p {
      padding-left: 10px;
      padding-right: 10px;
      &.active {
        pointer-events: none;
      }
    }
  }
  &__descr {
    color: $n_darkest;
    margin-top: 50px;
    @include max($s) {
      margin-bottom: 50px;
    }
    h3,
    h2 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0px;
      }
    }
    a {
      color: $b_sky;
    }
    &-title {
      font-family: "Travels", sans-serif;
      font-size: 24px;
      line-height: 33.6px;
      font-weight: 600;
      color: $n_darkest;
      margin-bottom: 20px;
    }
    &-info {
      color: #222736;
      margin-bottom: 20px;
    }
    &-more {
      display: flex;
      align-items: flex-end;

      color: $b_sky;
      cursor: pointer;

      &:hover {
        span {
          color: #096ade;
          text-decoration-line: underline;
        }
      }

      & > i {
        font-size: 20px;
        color: $b_sky;
        transition: all 0.3s linear;
      }
      & > span {
        margin-right: 13px;
        line-height: 18px;
      }
      &-mobile {
        font-size: 16px;
        line-height: 22.4px;
        font-weight: 500;
        font-family: "Travels", sans-serif;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &.active {
        & > i {
          transform: rotate(180deg);
        }
      }
    }
    &_more {
      height: 0px;
      opacity: 0;
      visibility: hidden;
      overflow: auto;

      &.active {
        height: auto;
        // max-height: 500px;
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    &-btn {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 20.7px;
      border: 1px solid $b_light;
      color: #000;
      border-radius: 2px;
      padding: 11px 20px;
      margin-right: 15px;
      & > i {
        color: $b_sky;
        font-size: 16px;
        margin-left: 10px;
      }
    }
    &-select {
      width: 100%;
      & > div {
        border: 1px solid $b_light;
        border-radius: 2px;
        padding: 7.3px; //test
        box-sizing: border-box;
      }
    }
    .drop {
      flex-shrink: 1;
      min-width: 180px;
    }
  }
  &__bottom {
    margin-top: 20px;
  }

  .catalog__head-line {
    display: flex;
    flex-direction: column;

    margin-bottom: 40px;
    @include max($s) {
      margin-bottom: 30px;
    }

    .drop {
      margin-right: 14px;
      @include max($s) {
        margin-right: 0px;
      }
      .drop__btn {
        padding: 12px 10px 9px;
      }

      span {
        font-size: 15px;
        line-height: 17px;
        font-weight: 400;
      }
    }
  }

  .empty-catalog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;

    & > p {
      text-align: center;
    }
  }
}
